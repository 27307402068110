import { useEffect, useRef, useState } from "react";
import useLang from "Shared/hooks/useLanguage";
import { assignFormValues } from "../../../Utils/Auth";
import UseMedicationAutoComplete from "../../hooks/Requisition/UseMedicationAutoComplete";
import MuiSkeleton from "../MuiSkeleton";
import { FindIndex } from "Utils/Common/CommonMethods";
import { toast } from "react-toastify";

const ActiveMedicationListCheckbox = ({
  Inputs,
  index,
  options = [],
  sysytemFieldName,
  displayFieldName,
  depControlIndex,
  fieldIndex,
  isDependency,
  repeatFieldSection,
  isDependencyRepeatFields,
  repeatFieldIndex,
  repeatDependencySectionIndex,
  repeatDepFieldIndex,
  setInputs,
  infectiousData,
  setInfectiousData,
  ArrayReqId,
  labelClassName,
  spanClassName,
  loading,
  error,
  noMedication,
}: any) => {
  const { t } = useLang();
  const [selectedMedications, setSelectedMedications] = useState<any>(options);
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const { suggestions, handleChange, handleKeyDown, handleClick } =
    UseMedicationAutoComplete(inputSearchRef);

  const [AssignedMedicationList, setAssignedMedicationList] = useState<any>(
    sysytemFieldName === "AssignedMedications"
      ? options.filter((opt: any) => opt.isSelected)
      : []
  );

  useEffect(() => {
    const isOtherMedication = sysytemFieldName === "OtherMedication";
    if (isOtherMedication) {
      assignFormValues(
        Inputs,
        index,
        depControlIndex,
        fieldIndex,
        options,
        isDependency,
        repeatFieldSection,
        isDependencyRepeatFields,
        repeatFieldIndex,
        repeatDependencySectionIndex,
        repeatDepFieldIndex,
        undefined,
        setInputs
      );
    }
  }, []);
  useEffect(() => {
    setSelectedMedications([]);
  }, [noMedication]);
  let medicationList: any;
  let disableCondition: any;
  Inputs[index].fields.map((item: any) => {
    if (item.systemFieldName === "AssignedMedications") {
      medicationList = item?.medicationList;
    }
    if (item.systemFieldName === "NoMedication") {
      disableCondition = item?.defaultValue;
      if (item?.defaultValue) {
        options?.map((i: any) => {
          i.isSelected = false;
        });
      }
    }
  });

  useEffect(() => {
    if (sysytemFieldName === "OtherMedication") {
      const updatedList = selectedMedications.map(
        ({ medicationName, medicationCode }: any) => ({
          medicationName,
          medicationCode,
        })
      );
      assignFormValues(
        Inputs,
        index,
        depControlIndex,
        fieldIndex,
        updatedList,
        isDependency,
        repeatFieldSection,
        isDependencyRepeatFields,
        repeatFieldIndex,
        repeatDependencySectionIndex,
        repeatDepFieldIndex,
        undefined,
        setInputs
      ).then((res) => {
        if (!infectiousData)
          return console.error("props.infectiousData is undefined or null");

        try {
          const infectiousDataCopy = JSON.parse(JSON.stringify(infectiousData));
          const dataIndex = FindIndex(infectiousData, ArrayReqId);
          if (dataIndex === -1)
            return console.error(
              "FindIndex returned -1, check ArrayReqId or data structure"
            );

          infectiousDataCopy[dataIndex].sections = res;
          setInfectiousData && setInfectiousData([...infectiousDataCopy]);
        } catch (error) {
          console.error("Error parsing infectiousData:", error);
        }
      });
    }
  }, [
    selectedMedications,
    sysytemFieldName,
    Inputs,
    index,
    depControlIndex,
    fieldIndex,
    isDependency,
    repeatFieldSection,
    isDependencyRepeatFields,
    repeatFieldIndex,
    repeatDependencySectionIndex,
    repeatDepFieldIndex,
    setInputs,
    infectiousData,
    setInfectiousData,
    ArrayReqId,
  ]);

  const updateFields = async (
    code: string,
    isSelected: boolean,
    selectedMedications: any
  ) => {
    if (Inputs[index]?.fields) {
      const medication = Inputs[index].fields
        .find((field: any) => field.systemFieldName === "AssignedMedications")
        ?.medicationList.find((med: any) => med.medicationCode === code);
      if (medication) medication.isSelected = isSelected;
    }

    const newInputs = await assignFormValues(
      Inputs,
      index,
      depControlIndex,
      fieldIndex,
      selectedMedications,
      isDependency,
      repeatFieldSection,
      isDependencyRepeatFields,
      repeatFieldIndex,
      repeatDependencySectionIndex,
      repeatDepFieldIndex,
      undefined,
      setInputs
    );

    const infectiousDataCopy = JSON.parse(JSON.stringify(infectiousData));
    infectiousDataCopy[FindIndex(infectiousData, ArrayReqId)].sections =
      newInputs;
    setInfectiousData && setInfectiousData([...infectiousDataCopy]);
  };

  const handleChangeCheckbox = (event: any, code: string, name: string) => {
    const medicationObj = { medicationCode: code, medicationName: name };
    setAssignedMedicationList((prevList: any) =>
      event.target.checked
        ? [...prevList, medicationObj]
        : prevList.filter((med: any) => med.medicationCode !== code)
    );
    updateFields(code, event.target.checked, AssignedMedicationList);
  };

  // const handleOtherMedication = async (item: any) => {
  //   const medicationObj = {
  //     medicationName: item.medicationName,
  //     medicationCode: item.medicationCode,
  //   };
  //   const updatedList = [...selectedMedications, medicationObj];
  //   setSelectedMedications(updatedList);

  //   try {
  //     const newInputs = await assignFormValues(
  //       Inputs,
  //       index,
  //       depControlIndex,
  //       fieldIndex,
  //       updatedList,
  //       isDependency,
  //       repeatFieldSection,
  //       isDependencyRepeatFields,
  //       repeatFieldIndex,
  //       repeatDependencySectionIndex,
  //       repeatDepFieldIndex,
  //       undefined,
  //       setInputs
  //     );

  //     const infectiousDataCopy = JSON.parse(JSON.stringify(infectiousData));
  //     infectiousDataCopy[FindIndex(infectiousData, ArrayReqId)].sections =
  //       newInputs;
  //     setInfectiousData && setInfectiousData([...infectiousDataCopy]);
  //   } catch (error) {
  //     console.error("Error updating fields:", error);
  //   }
  // };
  const handleOtherMedication = async (item: any) => {
    // Check if the medication is already in the selected list
    const isMedicationSelected = selectedMedications.some(
      (med: any) => med.medicationCode === item.medicationCode
    );

    if (isMedicationSelected) {
      // If already selected, show an error message
      toast.error("This medication is already selected.");
      return; // Prevent further execution
    }

    const medicationObj = {
      medicationName: item.medicationName,
      medicationCode: item.medicationCode,
    };

    // Add the new medication to the selected list
    const updatedList = [...selectedMedications, medicationObj];
    setSelectedMedications(updatedList);

    try {
      const newInputs = await assignFormValues(
        Inputs,
        index,
        depControlIndex,
        fieldIndex,
        updatedList,
        isDependency,
        repeatFieldSection,
        isDependencyRepeatFields,
        repeatFieldIndex,
        repeatDependencySectionIndex,
        repeatDepFieldIndex,
        undefined,
        setInputs
      );

      const infectiousDataCopy = JSON.parse(JSON.stringify(infectiousData));
      infectiousDataCopy[FindIndex(infectiousData, ArrayReqId)].sections =
        newInputs;
      setInfectiousData && setInfectiousData([...infectiousDataCopy]);
    } catch (error) {
      console.error("Error updating fields:", error);
    }
  };

  const removeMedication = async (code: number) => {
    const updatedList = selectedMedications.filter(
      (med: any) => med.medicationCode !== code
    );
    setSelectedMedications(updatedList);

    const newInputs = await assignFormValues(
      Inputs,
      index,
      depControlIndex,
      fieldIndex,
      updatedList,
      isDependency,
      repeatFieldSection,
      isDependencyRepeatFields,
      repeatFieldIndex,
      repeatDependencySectionIndex,
      repeatDepFieldIndex,
      undefined,
      setInputs
    );

    const infectiousDataCopy = JSON.parse(JSON.stringify(infectiousData));
    infectiousDataCopy[FindIndex(infectiousData, ArrayReqId)].sections =
      newInputs;
    setInfectiousData && setInfectiousData([...infectiousDataCopy]);
  };

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
        <h6 className="mb-3">{t(displayFieldName)}</h6>
        {sysytemFieldName === "AssignedMedications" && (
          <div className="row">
            {options.map(
              ({ medicationCode, medicationName, isSelected }: any) => (
                <div
                  className="col-lg-4 col-md-6 col-6 py-1"
                  key={medicationCode}
                >
                  <div className="form__group form__group--checkbox mb-3">
                    <label
                      className={
                        labelClassName
                          ? `${labelClassName} fw-400 text-break`
                          : "form-check form-check-inline form-check-solid m-0 fw-400 text-break"
                      }
                    >
                      <input
                        className="form-check-input h-20px w-20px"
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) =>
                          handleChangeCheckbox(
                            e,
                            medicationCode,
                            medicationName
                          )
                        }
                        disabled={disableCondition}
                      />
                      {loading ? (
                        <MuiSkeleton height={22} />
                      ) : (
                        <span className={spanClassName}>{medicationName}</span>
                      )}
                    </label>
                  </div>
                </div>
              )
            )}
          </div>
        )}
        {sysytemFieldName === "OtherMedication" && (
          <div className="d-flex flex-wrap gap-2 mt-2">
            <div
              style={{ position: "relative", width: "100%", maxWidth: "300px" }}
            >
              <input
                type="textarea"
                name="Medications"
                placeholder={t("Medications")}
                className="form-control bg-transparent position-relative"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                ref={inputSearchRef}
                disabled={disableCondition}
              />
              {suggestions.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: 0,
                    width: "100%",
                    maxHeight: "300px",
                    overflowY: "auto",
                    zIndex: 1000,
                    backgroundColor: "white",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "0.375rem",
                    marginTop: "5px",
                  }}
                >
                  {suggestions.map((item: any) => (
                    <div
                      key={item.medicationCode}
                      style={{
                        padding: "0.5rem 1rem",
                        cursor: "pointer",
                        backgroundColor: "white",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "#f3f4f6")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "white")
                      }
                      onClick={() => {
                        handleClick(item);
                        handleOtherMedication(item);
                      }}
                    >
                      <span id={item.medicationCode}>
                        {item.medicationName}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {sysytemFieldName === "OtherMedication" && (
          <div className="d-flex flex-wrap gap-2 mt-4">
            {selectedMedications.map(
              ({ medicationCode, medicationName }: any) => (
                <div
                  className="d-flex badge badge-secondary px-2 fw-500 gap-2 align-items-center pt-2"
                  key={medicationCode}
                >
                  <i
                    className="bi bi-x-lg cursor-pointer"
                    onClick={() => removeMedication(medicationCode)}
                  ></i>
                  <span>{medicationName}</span>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ActiveMedicationListCheckbox;
