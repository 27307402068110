import { toast } from "react-toastify";
import {
  assignFormValues,
  fnBrowserDetect,
  getOS,
  SignatureForPatient,
} from "Utils/Auth";

// const Button = (props: any) => {
//   let PatientDiscriptionIndex = props.Inputs[props.index].fields.findIndex(
//     (fieldsData: any) => fieldsData?.systemFieldName == "PatientDescription"
//   );
//   let PatientFullNameIndex = props.Inputs[props.index].fields.findIndex(
//     (fieldsData: any) => fieldsData?.systemFieldName == "PatientFullName"
//   );
//   let PatientOptionIndex = props?.Inputs[props?.index]?.fields?.findIndex(
//     (fieldsData: any) => fieldsData?.systemFieldName == "PatientOption"
//   );
//   const handleClick = async () => {
//     let browserInfo = fnBrowserDetect();
//     let osInfo = getOS();
//     if (
//       props?.Inputs[props?.index]?.fields[PatientFullNameIndex]?.defaultValue &&
//       props?.Inputs[props?.index]?.fields[PatientOptionIndex]?.defaultValue &&
//       props?.Inputs[props?.index]?.fields[PatientDiscriptionIndex]?.defaultValue
//     ) {
//       await SignatureForPatient(
//         props.Inputs,
//         props.index,
//         osInfo,
//         browserInfo,
//         props?.setInputs
//       );

//       props.setInputs && props.setInputs(props.Inputs);
//     } else {
//       toast.error("Select information in order to create signature");
//     }
//   };

//   return (
//     <div className={`${props.parentDivClassName} mb-5`}>
//       <button className="btn btn-sm btn-primary" onClick={handleClick}>
//         {props.label}
//       </button>
//     </div>
//   );
// };
const Button = (props: any) => {
  let PatientDiscriptionIndex = props.Inputs[props.index].fields.findIndex(
    (fieldsData: any) => fieldsData?.systemFieldName == "PatientDescription"
  );
  let PatientFullNameIndex = props.Inputs[props.index].fields.findIndex(
    (fieldsData: any) => fieldsData?.systemFieldName == "PatientFullName"
  );
  let PatientOptionIndex = props?.Inputs[props?.index]?.fields?.findIndex(
    (fieldsData: any) => fieldsData?.systemFieldName == "PatientOption"
  );

  const handleClick = async () => {
    let browserInfo = fnBrowserDetect();
    let osInfo = getOS();
    if (
      props?.Inputs[props?.index]?.fields[PatientFullNameIndex]?.defaultValue &&
      props?.Inputs[props?.index]?.fields[PatientOptionIndex]?.defaultValue &&
      props?.Inputs[props?.index]?.fields[PatientDiscriptionIndex]?.defaultValue
    ) {
      // Wait for the signature process to finish before setting inputs
      await SignatureForPatient(
        props.Inputs,
        props.index,
        osInfo,
        browserInfo,
        props?.setInputs
      );
      let input = await assignFormValues(
        props.Inputs,
        props.index,
        props.depControlIndex,
        props.fieldIndex,
        props.Inputs[props.index].fields[5].defaultValue,
        props.isDependency,
        props.repeatFieldSection,
        props.isDependencyRepeatFields,
        props.repeatFieldIndex,
        props.repeatDependencySectionIndex,
        props.repeatDepFieldIndex,
        undefined,
        props?.setInputs
      );
      console.log(input, "input2222");
      console.log(props.Inputs[props.index].fields[5].defaultValue, "Button");
      props.setInputs(input);
      props.setSignPadValue(props.Inputs[props.index].fields[5].defaultValue);
    } else {
      toast.error("Select information in order to create signature");
    }
  };

  return (
    <div className={`${props.parentDivClassName} mb-5`}>
      <button className="btn btn-sm btn-primary" onClick={handleClick}>
        {props.label}
      </button>
    </div>
  );
};

export default Button;
